<template>
  <!-- Loading -->
  <div
    v-if="true"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const { route } = useRouter()

    const verifyUserEmail = () => {
      store.dispatch('verifyEmail', { token: route.value.params.token }).then(response => {
        localStorage.setItem('accessToken', response.data.accessToken)
        router.push({ name: 'manage-events' })
      })
    }
    verifyUserEmail()

    return {}
  },
}
</script>
